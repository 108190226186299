export default defineNuxtRouteMiddleware((to, from) => {
  const config = useRuntimeConfig();

  const isMaintenance = config.public.isMaintenance as unknown as boolean;
  // console.log(isMaintenance);
  if (isMaintenance && to.path !== "/maintenance") {
    // console.log("go to maintenance");
    return navigateTo("/maintenance");
  } else if (!isMaintenance && to.path === "/maintenance") {
    // console.log("get out of maintenance");
    return navigateTo("/");
  } else {
    // console.log("keep going");
    return;
  }
});
