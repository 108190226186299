export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#050f30"},{"name":"theme-color","content":"#ffffff"},{"property":"og:locale","content":"cs_CZ"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Shark's Barber"},{"property":"og:description","content":"Moderní barbershopy na Žižkově a v Nuslích. Profesionální péče, kvalitní produkty a pití zdarma vám zajistí dokonalý zážitek v unikátním prostředí."},{"property":"description","content":"Moderní barbershopy na Žižkově a v Nuslích. Profesionální péče, kvalitní produkty a pití zdarma vám zajistí dokonalý zážitek v unikátním prostředí."},{"property":"og:url","content":"https://www.sharksbarber.cz/"},{"property":"og:site_name","content":"Shark's Barber"},{"property":"og:image","content":"https://www.sharksbarber.cz/og-image.jpg"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#050f30"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"