import { default as gdprUn6LeV527aMeta } from "/opt/buildhome/repo/pages/gdpr.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as maintenancefe5TKEsKxKMeta } from "/opt/buildhome/repo/pages/maintenance.vue?macro=true";
import { default as nusleWL9GlWVBSGMeta } from "/opt/buildhome/repo/pages/nusle.vue?macro=true";
import { default as sluzbyHr7Wu9LA7gMeta } from "/opt/buildhome/repo/pages/sluzby.vue?macro=true";
import { default as zizkovNpCVnoqkwdMeta } from "/opt/buildhome/repo/pages/zizkov.vue?macro=true";
export default [
  {
    name: "gdpr",
    path: "/gdpr",
    component: () => import("/opt/buildhome/repo/pages/gdpr.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancefe5TKEsKxKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/maintenance.vue")
  },
  {
    name: "nusle",
    path: "/nusle",
    component: () => import("/opt/buildhome/repo/pages/nusle.vue")
  },
  {
    name: "sluzby",
    path: "/sluzby",
    component: () => import("/opt/buildhome/repo/pages/sluzby.vue")
  },
  {
    name: "zizkov",
    path: "/zizkov",
    component: () => import("/opt/buildhome/repo/pages/zizkov.vue")
  }
]